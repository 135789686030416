<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>{{ title }}</strong>
            <a-button type="reset" class="btn btn-sm btn-outline-danger mb-0 ml-3" @click="resetForm()">
              Back
            </a-button>
          </template>
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="Nama">
              <div class="row" style="padding: 10px 20px;">
                <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                  <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3" style="padding-right: unset;">
                      <label class="mt-1">No. Register Nasabah</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <!-- :readOnly="action === 'update'" -->
                      <a-input style="width: 150px;" placeholder="No. Register Nasabah" v-model="formData.kode"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Tanggal Register</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-date-picker
                        style="width: 125px;"
                        v-model="formData.tglregister"
                        :allowClear="false"
                        format="DD-MM-YYYY"
                        @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['formData', 'tglregister'])"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Nama Lengkap</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input placeholder="nama lengkap nasabah" v-model="formData.nama" @blur="findName" @keydown.enter="findName"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Jenis Kelamin</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.kelamin" style="width: 150px;">
                        <a-select-option value="L">Laki-Laki</a-select-option>
                        <a-select-option value="P">Perempuan</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Golongan Darah</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.golongandarah" style="width: 70px;">
                        <a-select-option value="A">A</a-select-option>
                        <a-select-option value="B">B</a-select-option>
                        <a-select-option value="AB">AB</a-select-option>
                        <a-select-option value="O">O</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Tempat/Tgl Lahir</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input style="width: 200px;" v-model="formData.tempatlahir"></a-input>
                      /
                      <a-date-picker
                        style="width: 125px;"
                        v-model="formData.tgllahir"
                        :allowClear="false"
                        format="DD-MM-YYYY"
                        @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['formData', 'tgllahir'])"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Status Perkawinan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.statusperkawinan" style="width: 150px;">
                        <a-select-option value="K">Kawin</a-select-option>
                        <a-select-option value="B">Belum Kawin</a-select-option>
                        <a-select-option value="D">Duda/Janda</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1" style="padding-right: unset !important;">
                      <label>No. SIM/KTP/PASPOR</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input style="width: 250px;" v-model="formData.ktp"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Tgl Berlaku</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-date-picker
                        style="width: 125px;"
                        v-model="formData.tglktp"
                        :allowClear="false"
                        format="DD-MM-YYYY"
                        @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['formData', 'tglktp'])"
                      />
                      <a-checkbox class="ml-2">Seumur hidup</a-checkbox>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Agama</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.agama" style="width: 300px;">
                        <a-select-option v-for="(item, index) in dataAgama" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Perkerjaan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.pekerjaan" style="width: 300px;">
                        <a-select-option v-for="(item, index) in dataPekerjaan" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Keterkaitan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.keterkaitan" style="width: 100%;">
                        <a-select-option value="1">Pihak Terkait</a-select-option>
                        <a-select-option value="2">Pihak Tidak Terkait</a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                  <a-table
                    :scroll="{ y: 300 }"
                    :columns="kembarnamacolumn"
                    :dataSource="kembarnamadatatable"
                    :pagination="false"
                    size="small"
                  ></a-table>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Alamat Rumah / Kantor">
              <div class="row" style="padding: 8px 20px;">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="row">
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong>Alamat Sesuai KTP</strong></a-divider>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><a-checkbox v-model="alamatktpdantempattinggalsama" @change="samakandatachange">Samakan data</a-checkbox></a-divider>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong>Tempat Tinggal</strong></a-divider>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong class="mb-1">Alamat Sesuai KTP</strong></a-divider>
                  <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                      <label class="mt-1">Alamat</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.alamat" placeholder="Alamat"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Telepon</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.telepon" style="width: 250px;" placeholder="Telepon"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Faximile</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.fax" style="width: 250px;" placeholder="Faximile"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Kodya/Kab</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.kodya" @change="(e) => changeKodya(e, 'KTP')" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataKodyaKTP" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Kecamatan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.kecamatan" @change="(e) => changeKecamatan(e, 'KTP', 'kodya')" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataKecamatanKTP" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Desa/Dus</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.kelurahan" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataDesaKTP" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Kode Pos</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.kodepos" style="width: 100px;" placeholder="Kode Pos"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>RT/RW</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.rtrw" style=";" placeholder="RT/RW"></a-input>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong class="mb-1">Tempat Tinggal</strong></a-divider>
                  <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                      <label class="mt-1">Alamat</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.alamattinggal" placeholder="Alamat"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Telepon</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.telepontinggal" style="width: 250px;" placeholder="Telepon"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Faximile</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.faxtinggal" style="width: 250px;" placeholder="Faximile"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Kodya/Kab</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.kodyatinggal" @change="(e) => changeKodya(e, 'Tinggal')" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataKodyaTinggal" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Kecamatan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.kecamatantinggal" @change="(e) => changeKecamatan(e, 'Tinggal', 'kodyatinggal')" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataKecamatanTinggal" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Desa/Dus</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.desatinggal" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataDesaTinggal" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Kode Pos</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.kodepostinggal" style="width: 100px;" placeholder="Kode Pos"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>RT/RW</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.rtrwtinggal" style=";" placeholder="RT/RW"></a-input>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-3">
                  <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong class="mb-1">Alamat Kantor</strong></a-divider>
                  <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                      <label class="mt-1">Alamat</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.alamatkantor" placeholder="Alamat"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Telepon</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.teleponkantor" style="width: 250px;" placeholder="Telepon"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Faximile</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.faxkantor" style="width: 100px;" placeholder="Faximile"></a-input>
                      <label class="ml-3">Kode Pos</label>
                      <a-input class="ml-2" v-model="formData.kodepos" style="width: 100px;" placeholder="Kode Pos"></a-input>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-3">
                  <div class="row">
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <label class="mt-1">NPWP Perorangan</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.npwp" placeholder="NPWP Perorangan"></a-input>
                    </div>
                    <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong class="mb-1">Khusus Karyawan PG</strong></a-divider>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Bagian</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                      <a-input v-model="formData.bagian" placeholder="Bagian"></a-input>
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="Lembaga, Data SID dan SLIK">
              <div class="row" style="padding: 8px 20px;">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong class="mb-1">Lembaga</strong></a-divider>
                  <div class="row">
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <label class="mt-1">Nama Lembaga BU/BH</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.namalembaga" placeholder="Nama Lembaga BU/BH"></a-input>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                      <label>Tanggal Pendirian</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-date-picker
                        style="width: 125px;"
                        v-model="formData.tanggalpendirian"
                        :allowClear="true"
                        format="DD-MM-YYYY"
                        @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['formData', 'tanggalpendirian'])"
                      />
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                      <label>Alamat</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.alamatlembaga" placeholder="Alamat Lembaga"></a-input>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                      <label>No. Akte Pendirian</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.akteperubahanbu" placeholder="No. Akte Pendirian Lembaga"></a-input>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                      <label>No NPWP</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.npwpbu" placeholder="No NPWP Lembaga"></a-input>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Kodya/Kab</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.kodyalembaga" @change="(e) => changeKodya(e, 'Lembaga')" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataKodyaLembaga" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Kecamatan</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.kecamatanlembaga" @change="(e) => changeKecamatan(e, 'Lembaga', 'kodyalembaga')" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataKecamatanLembaga" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Desa/Kelurahan</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.kelurahanlembaga" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataDesaLembaga" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong class="mb-1">Data SID</strong></a-divider>
                  <div class="row">
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <label class="mt-1">Nama Ibu Kandung</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.namaibukandung" placeholder="Nama Ibu Kandung"></a-input>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                      <label>Nama Alias</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.namaalias" placeholder="Nama Alias"></a-input>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                      <label>Status / Gelar</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-select show-search option-filter-prop="children" :filter-option="filterOption" v-model="formData.gelar" style="width: 100%;">
                        <a-select-option v-for="(item, index) in dataGelar" :key="index" :value="item.kode">{{ item.kode + ' - ' + item.keterangan }}</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                      <label>No Paspor</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.nopaspor" placeholder="No Paspor"></a-input>
                    </div>
                  </div>
                  <div class="row">
                    <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong class="mb-1">SLIK</strong></a-divider>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <label class="mt-1">Nama Pasangan</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.namapasangan" placeholder="Nama Pasangan"></a-input>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                      <label>No KTP Pasangan</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-input v-model="formData.ktppasangan" placeholder="No KTP Pasangan"></a-input>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                      <label>Tgl Lahir</label>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-date-picker
                        style="width: 125px;"
                        v-model="formData.tgllahirpasangan"
                        :allowClear="false"
                        format="DD-MM-YYYY"
                        @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['formData', 'tgllahirpasangan'])"
                      />
                      <a-button class="btn btn-outline-success ml-2" @click="handleSubmit">Simpan</a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <!-- <a-tab-pane key="4" tab="Data SID">
              Content of Tab Pane 4
            </a-tab-pane> -->
            <!-- <a-tab-pane key="5" tab="SLIK">
              Content of Tab Pane 5
            </a-tab-pane> -->
          </a-tabs>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  watch: {
    'formData.alamat'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.alamattinggal = newValue
      }
    },
    'formData.telepon'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.telepontinggal = newValue
      }
    },
    'formData.fax'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.faxtinggal = newValue
      }
    },
    'formData.kodepos'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.kodepostinggal = newValue
      }
    },
    'formData.rtrw'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.rtrwtinggal = newValue
      }
    },
    'formData.alamattinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.alamat = newValue
      }
    },
    'formData.telepontinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.telepon = newValue
      }
    },
    'formData.faxtinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.fax = newValue
      }
    },
    'formData.kodepostinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.kodepos = newValue
      }
    },
    'formData.rtrwtinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.rtrw = newValue
      }
    },
  },
  data: function () {
    return {
      dateFormat: ['DD/MM/YYYY', 'DD/MM/YY'],
      form: this.$form.createForm(this),
      editdata: JSON.parse(localStorage.getItem('cbseditregisternasabahdata')),
      alamatktpdantempattinggalsama: true,
      config: {
        rules: [
          { type: 'object', required: false, message: 'Tolong pilih tanggal!' },
        ],
      },
      errorMsg: null,
      successMsg: null,
      action: null,
      title: 'Register Nasabah',
      showHistory: true,
      showInformation: false,
      kembarnamacolumn: [
        {
          title: 'Kode',
          rowKey: 'kode',
          dataIndex: 'kode',
          scopedSlots: { customRender: 'kode' },
          // width: 100,
        },
        {
          title: 'Nama',
          dataIndex: 'nama',
          scopedSlots: { customRender: 'nama' },
          // width: 100,
        },
        {
          title: 'KTP',
          dataIndex: 'ktp',
          scopedSlots: { customRender: 'ktp' },
          // width: 100,
        },
        {
          title: 'Alamat',
          dataIndex: 'alamat',
          scopedSlots: { customRender: 'alamat' },
          // width: 100,
        },
      ],
      kembarnamadatatable: [],
      dataKodya: [],
      dataKecamatan: [],
      dataDesa: [],
      dataKodyaKTP: [],
      dataKecamatanKTP: [],
      dataDesaKTP: [],
      dataKodyaTinggal: [],
      dataKecamatanTinggal: [],
      dataDesaTinggal: [],
      dataKodyaLembaga: [],
      dataKecamatanLembaga: [],
      dataDesaLembaga: [],
      dataAgama: [],
      dataPekerjaan: [],
      dataGelar: [],
      formData: {
        id: null,
        kode: '01',
        nama: null,
        kelamin: null,
        golongandarah: null,
        tgllahir: moment(),
        tempatlahir: null,
        statusperkawinan: null,
        ktp: null,
        agama: null,
        pekerjaan: null,
        alamat: null,
        kodepos: null,
        telepon: null,
        fax: null,
        kodya: null,
        kecamatan: null,
        kelurahan: null,
        rtrw: null,
        alamatkantor: null,
        teleponkantor: null,
        faxkantor: null,
        kodeposkantor: null,
        path: null,
        ao: null,
        path1: null,
        tglregister: moment(),
        namalembaga: null,
        alamatlembaga: null,
        noaktependirian: null,
        nonpwp: null,
        kodyalembaga: null,
        kecamatanlembaga: null,
        kelurahanlembaga: null,
        alamattinggal: null,
        kodepostinggal: null,
        telepontinggal: null,
        faxtinggal: null,
        kodyatinggal: null,
        kecamatantinggal: null,
        desatinggal: null,
        rtrwtinggal: null,
        tglktp: moment(),
        npwp: null,
        bagian: null,
        namaibukandung: null,
        namaalias: null,
        status: null,
        gelar: null,
        nopaspor: null,
        foto2: null,
        foto3: null,
        din: null,
        iddebitur: null,
        statuskirim: null,
        ibukandung: null,
        keterkaitan: null,
        namapasangan: null,
        ktppasangan: null,
        tgllahirpasangan: moment(),
        jumlahtanggungan: 0,
        statussid: null,
        nik_pasangan: null,
        nama_pasangan: null,
        tempatlahir_pasangan: null,
        tgllahir_pasangan: moment(),
        updatedate: null,
        kewarganegaraan: null,
        sumberdana: null,
        pendapatanratarata: null,
        maksudtujuan: null,
        bidangusaha: null,
        kotapendirian: null,
        tanggalpendirian: null,
        badanhukum: null,
        teleponlembaga: null,
        aktif: null,
        bu: null,
        golongandebiturbu: null,
        kecamatanbu: null,
        kelurahanbu: null,
        tglperubahanbu: moment(),
        tglbu: moment(),
        hubunganljkbu: null,
        bidangusahabu: null,
        kodeposbu: null,
        kodyabu: null,
        alamatbu: null,
        emailbu: null,
        teleponbu: null,
        akteperubahanbu: null,
        aktebu: null,
        tempatbu: null,
        jenisbu: null,
        namabu: null,
        npwpbu: null,
      },
    }
  },
  mounted: function () {
    // const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Nasabah'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Nasabah'
    }
    this.getData()
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'register nasabah' })
  },
  methods: {
    moment,
    samakandatachange(e) {
      // console.log('e', e)
      if (this.alamatktpdantempattinggalsama) {
        if (this.formData.alamat !== '') {
          this.formData.alamattinggal = this.formData.alamat
        }
        if (this.formData.telepon !== '') {
          this.formData.telepontinggal = this.formData.telepon
        }
        if (this.formData.fax !== '') {
          this.formData.faxtinggal = this.formData.fax
        }
        if (this.formData.kodepos !== '') {
          this.formData.kodepostinggal = this.formData.kodepos
        }
        if (this.formData.rtrw !== '') {
          this.formData.rtrwtinggal = this.formData.rtrw
        }
        if (this.formData.alamattinggal !== '') {
          this.formData.alamat = this.formData.alamattinggal
        }
        if (this.formData.telepontinggal !== '') {
          this.formData.telepon = this.formData.telepontinggal
        }
        if (this.formData.faxtinggal !== '') {
          this.formData.fax = this.formData.faxtinggal
        }
        if (this.formData.kodepostinggal !== '') {
          this.formData.kodepos = this.formData.kodepostinggal
        }
        if (this.formData.rtrwtinggal !== '') {
          this.formData.rtrw = this.formData.rtrwtinggal
        }
      }
    },
    changeDateInput(dateMoment, dateString, arrdata) {
      this[arrdata[0]][arrdata[1]] = dateMoment
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('arrdata', arrdata)
    },
    // async getAgama() {
    //   var dataagama = await lou.customUrlGet('v_agama')
    //   this.dataAgama = dataagama.data

    //   var datakodya = await lou.customUrlGet('kodya')
    //   this.dataKodya = datakodya.data
    // },
    async getKecamatan() {
      var wherekodya = {
        where: {
          regency_id: this.formData.Kodya,
        },
      }
      var datakecamatan = await lou.customUrlGet('kecamatan', wherekodya)
      this.dataKecamatan = datakecamatan.data
    },
    async getKelurahan() {
      var whereKecamatan = {
        where: {
          district_id: this.formData.Kecamatan,
        },
      }
      var datakelurahan = await lou.customUrlGet('kelurahan', whereKecamatan)
      this.dataKelurahan = datakelurahan.data
    },
    async getData() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(x => x.mykey === 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id)
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet('master', false, true)
        var master = {
          mykey: 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(x => x.mykey === 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id)
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      this.dataKodya = this.allMaster.kodya
      this.dataKodyaKTP = this.allMaster.kodya
      this.dataKodyaTinggal = this.allMaster.kodya
      this.dataKodyaLembaga = this.allMaster.kodya
      this.dataAgama = this.allMaster.agama
      this.dataPekerjaan = this.allMaster.pekerjaan
      this.dataGelar = this.allMaster.statusgelar
    },
    changeKodya(e, from) {
      // console.log('e', e)
      // console.log('from', from)
      var ndata = this.allMaster.kecamatan.filter(x => x.kodya === e)
      // console.log('ndata', ndata)
      this['dataKecamatan' + from] = ndata
    },
    changeKecamatan(e, from, kodya) {
      // console.log('e', e)
      // console.log('from', from)
      // console.log(kodya, this.formData[kodya])
      var ndata = this.allMaster.kelurahan.filter(x => x.kecamatan === e && x.kodya === this.formData[kodya])
      // console.log('ndata', ndata)
      this['dataDesa' + from] = ndata
    },
    getEditData() {
      // var res = await lou.customUrlGet('registernasabah', { where: { id: this.editdata.id } })
      this.formData = this.$g.clone(this.editdata)
    },
    async findName() {
      var res = await lou.customUrlGet('carinasabah?Nama=' + this.formData.nama)
      if (res) {
        this.kembarnamadatatable = res.data
      }
    },
    handleSubmit(e) {
      Modal.confirm({
        title: 'Are you sure the data is correct?',
        content: 'You can edit the data later! ',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: async () => {
          // Should format date value before submit.
          var res = false
          var fd = this.formData
          fd.tgllahir = this.formData.tgllahir !== null ? this.formData.tgllahir.format('YYYY-MM-DD') : null
          fd.tglregister = this.formData.tglregister !== null ? this.formData.tglregister.format('YYYY-MM-DD') : null
          fd.tglktp = this.formData.tglktp !== null ? this.formData.tglktp.format('YYYY-MM-DD') : null
          fd.tgllahirpasangan = this.formData.tgllahirpasangan !== null ? this.formData.tgllahirpasangan.format('YYYY-MM-DD') : null
          fd.tgllahir_pasangan = this.formData.tgllahirpasangan !== null ? this.formData.tgllahirpasangan : null
          fd.tglperubahanbu = this.formData.tglperubahanbu !== null ? this.formData.tglperubahanbu.format('YYYY-MM-DD') : null
          fd.tglbu = this.formData.tglbu !== null ? this.formData.tglbu.format('YYYY-MM-DD') : null
          if (this.action === 'update') {
            res = await lou.customUrlPut('registernasabah', fd.id, fd)
          } else {
            delete fd.id
            res = await lou.customUrlPost('registernasabah', fd)
          }
          if (res) {
            Modal.confirm({
              title: 'Kembali?',
              content: 'Kembali ke halaman data?! ',
              okText: 'Yes',
              okType: 'primary',
              cancelText: 'No',
              onOk: () => {
              // Should format date value before submit.
                this.$router.push('/cbstdn/registernasabah/data')
              },
              onCancel: () => {
                Modal.confirm({
                  title: 'Reset Form?',
                  content: 'Data Input Akan direset?! ',
                  okText: 'Yes',
                  okType: 'primary',
                  cancelText: 'No',
                  onOk: () => {
                    this.resetForm()
                  },
                  onCancel: () => {
                    fd.tgllahir = this.formData.tgllahir !== null ? moment(this.formData.tgllahir, 'YYYY-MM-DD') : moment()
                    fd.tglregister = this.formData.tglregister !== null ? moment(this.formData.tglregister, 'YYYY-MM-DD') : moment()
                    fd.tglktp = this.formData.tglktp !== null ? moment(this.formData.tglktp, 'YYYY-MM-DD') : moment()
                    fd.tgllahirpasangan = this.formData.tgllahirpasangan !== null ? moment(this.formData.tgllahirpasangan, 'YYYY-MM-DD') : moment()
                    fd.tgllahir_pasangan = this.formData.tgllahir_pasangan !== null ? moment(this.formData.tgllahir_pasangan, 'YYYY-MM-DD') : moment()
                    fd.tglperubahanbu = this.formData.tglperubahanbu !== null ? moment(this.formData.tglperubahanbu, 'YYYY-MM-DD') : moment()
                    fd.tglbu = this.formData.tglbu !== null ? moment(this.formData.tglbu, 'YYYY-MM-DD') : moment()
                  // console.log('Cancel')
                  },
                })
              },
            })
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    resetForm() {
      localStorage.removeItem('cbseditregisternasabahdata')
      this.formData = {
        id: null,
        kode: '01',
        nama: null,
        kelamin: null,
        golongandarah: null,
        tgllahir: moment(),
        tempatlahir: null,
        statusperkawinan: null,
        ktp: null,
        agama: null,
        pekerjaan: null,
        alamat: null,
        kodepos: null,
        telepon: null,
        fax: null,
        kodya: null,
        kecamatan: null,
        kelurahan: null,
        rtrw: null,
        alamatkantor: null,
        teleponkantor: null,
        faxkantor: null,
        kodeposkantor: null,
        path: null,
        ao: null,
        path1: null,
        tglregister: moment(),
        namalembaga: null,
        alamatlembaga: null,
        noaktependirian: null,
        nonpwp: null,
        kodyalembaga: null,
        kecamatanlembaga: null,
        kelurahanlembaga: null,
        alamattinggal: null,
        kodepostinggal: null,
        telepontinggal: null,
        faxtinggal: null,
        kodyatinggal: null,
        kecamatantinggal: null,
        desatinggal: null,
        rtrwtinggal: null,
        tglktp: moment(),
        npwp: null,
        bagian: null,
        namaibukandung: null,
        namaalias: null,
        status: null,
        gelar: null,
        nopaspor: null,
        foto2: null,
        foto3: null,
        din: null,
        iddebitur: null,
        statuskirim: null,
        ibukandung: null,
        keterkaitan: null,
        namapasangan: null,
        ktppasangan: null,
        tgllahirpasangan: moment(),
        jumlahtanggungan: 0,
        statussid: null,
        nik_pasangan: null,
        nama_pasangan: null,
        tempatlahir_pasangan: null,
        tgllahir_pasangan: moment(),
        updatedate: null,
        kewarganegaraan: null,
        sumberdana: null,
        pendapatanratarata: null,
        maksudtujuan: null,
        bidangusaha: null,
        kotapendirian: null,
        tanggalpendirian: null,
        badanhukum: null,
        teleponlembaga: null,
        aktif: null,
        bu: null,
        golongandebiturbu: null,
        kecamatanbu: null,
        kelurahanbu: null,
        tglperubahanbu: moment(),
        tglbu: moment(),
        hubunganljkbu: null,
        bidangusahabu: null,
        kodeposbu: null,
        kodyabu: null,
        alamatbu: null,
        emailbu: null,
        teleponbu: null,
        akteperubahanbu: null,
        aktebu: null,
        tempatbu: null,
        jenisbu: null,
        namabu: null,
        npwpbu: null,
      }
      this.$router.push('/cbstdn/registernasabah/data')
    },
  },
}
</script>
